import React from 'react';
import PropTypes from 'prop-types';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { DeskBanner, Press, Reviews } from '@beelineloans/cx-library/src/components/layout/panels';
// import REVIEWS from '../../../shared/data/reviews.json';
import PRESS from '../../../shared/data/press.json';

const BeelineReviews = ({ pageContext }) => {
  const { reviews } = pageContext;

  return (
    <NavOnlyTemplate
      headerProps={
        {
          options: {
            headerRatings: true
          }
        }
      }
    >
      <SEO
        path={CONST.LINKS.MAIN.REVIEWS}
        title="Beeline loans reviews"
        description="Check out real-life reviews from Beeline customers."
      />
      <Reviews reviews={reviews} />
      <Press data={PRESS} />
      <DeskBanner />
    </NavOnlyTemplate>
  );
};

BeelineReviews.propTypes = {
  pageContext: PropTypes.object
};

BeelineReviews.defaultProps = {};

export default BeelineReviews;
